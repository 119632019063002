




































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

@Component({})
export default class AUser extends Vue {
    // 字典
    config = store.state.system.config;
    // 表格数据
    tableData = [];
    // 加载状态
    loading = false;
    // 总条数
    total = 0;
    // 搜索条件
    search = {
        channelId: "",
        bidMonth: "",
        tradingCenterCode: [],
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 20, //每页数量
        },
    };
    // 搜索 search:true 重置翻页
    searchTable(search = false) {
        this.loading = true;
        if (search) {
            this.search.pagination = {
                pageSize: 20,
                page: 0,
            };
        }
        api
            .post("J31101", {
                ...this.search,
                tradingCenterCode: this.search.tradingCenterCode.join(","),
            })
            .then((data) => {
                this.total = data.total;
                this.tableData = data.list;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    // 去员工列表
    toEmployee(companyId: any) {
        this.$router.push({
            path: 'staff',
            query: {companyId}
        })
    }

    toAdd() {
        this.$router.push({
            path: 'tinymce'
        })
    }

    toEdit(val: any) {
        this.$router.push({
            path: 'tinymce',
            query: {id: val}
        })
    }

    lookDialog = false
    detailInfo = {}
    toLook(row: any) {
        api.post('J31102', {id: row.id}).then(res => {
            console.log(res);
            this.detailInfo = res
            this.lookDialog = true

        })
    }


    del(row: any) {
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            api.post('J31105', {id: row.id}).then(res => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.searchTable()
            })

        })
    }


    activated() {
        this.searchTable();
    }
}
